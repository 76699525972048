import React, { useState, useEffect, useRef } from "react";
import { Grid, MenuItem, Box } from "@material-ui/core";
import useStyles from "./Dashboard.style";
import { CustomModal } from "jangl-common-js";
import { useTranslation } from "react-i18next";
import theme from "../../CustomTheme";
import { useSelector, useDispatch } from "react-redux";
import { dataSelectors, dataOperations } from "../../store/logs";
import {
  getCursorFromDate,
  getFilterOptions,
  getQueryParams,
  getTierOptions
} from "./LogsUtils";
import { verticalSelectors, verticalOperations } from "../../store/verticals";
import TabComponent from "./TabComponent";
import LogsPopupModal from "../DataPopup/LogsPopupModal";
import { modalLogs } from "./../DataPopup/DataViewHandler";
import {
  EVENT_TYPE_OPTIONS,
  TAB_STATUS_OPTIONS,
  DURATION_OPTIONS,
  headCellJanglId,
  headCellVertical,
  headCellBuyer,
  headCellVendor,
  headCellDuration
} from "../../Constants";

const Dashboard = (props) => {
  const { setting } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const allowCache = true;
  const [hasExecuted, setHasExecuted] = useState(false);
  const [filtersEdited, setFiltersEdited] = useState(false);

  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date());
  const [statusTags, setStatusTags] = useState([]);
  const [durationTags, setDurationTags] = useState([]);
  const [vendorTags, setVendorTags] = useState([]);
  const [buyerTags, setBuyerTags] = useState([]);
  const [verticalTags, setVerticalTags] = useState([]);
  const [searchTags, setSearchTags] = useState([]);

  const [cursor, setCursor] = useState("");
  const [initialCursor, setInitialCursor] = useState(getCursorFromDate(date));
  const [isCursorSet, setIsCursorSet] = useState(false);

  //ellipses option row
  const [row, setRow] = useState(null);
  const [rowViewLogs, setRowViewLogs] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  //Filter options Selectors
  const verticalDataWebLeads = useSelector(verticalSelectors.getVerticalsData);
  const verticalDataCalls = useSelector(
    verticalSelectors.getVerticalsDataCalls
  );
  const vendorNamesData = useSelector(dataSelectors.getVendorNamesData);
  const buyerNamesData = useSelector(dataSelectors.getBuyerNamesData);
  const vendorCampaignData = useSelector(dataSelectors.getSourceCampaignData);
  const buyerCampaignData = useSelector(dataSelectors.getEndPointCampaignData);
  //TableData Selector
  const pingPostLogsAPIResult = useSelector(dataSelectors.getPingPostLogsData);
  const [pingPostLogsData, setPingPostLogsData] = useState([]);

  //Get filters options variables
  const [verticalOptions, setVerticalOptions] = useState([]);
  const [buyerOptions, setEndPointOptions] = useState([]);
  const [vendorOptions, setSourceOptions] = useState([]);
  const [tierOptions, setTierOptions] = useState([]);
  const [buyerCampaignOptions, setBuyerCampaignOptions] = useState([]);
  const [vendorCampaignOptions, setVendorCampaignOptions] = useState([]);

  const [tierTags, setTierTags] = useState([]);
  const [buyerCampaignTags, setBuyerCampaignTags] = useState([]);
  const [vendorCampaignTags, setVendorCampaignTags] = useState([]);
  const [eventTypeTags, setEventTypeTags] = useState([]);
  //refs
  const eventTypeRef = useRef(null);
  const verticalRef = useRef(null);
  const searchRef = useRef(null);
  const durationRef = useRef(null);
  const statusRef = useRef(null);
  const buyerCampiagnRef = useRef(null);
  const verticalTierRef = useRef(null);
  const vendorRef = useRef(null);
  const vendorCampiagnRef = useRef(null);
  const buyerRef = useRef(null);
  const janglIdRef = useRef(null);

  const [janglIdTags, setJanglIdTags] = useState([]);
  const [filterComponents, setFilterComponents] = useState([]);

  /**
   * Set filter's vertical tags value
   * @param {*} event
   */
  const setVerticalTagsHandler = (event) => {
    setVerticalTags(event);
    setHasExecuted(false);
    setFiltersEdited(true);
  };

  const setTierTagsHandler = (event) => {
    setTierTags(event);
    setHasExecuted(false);
    setFiltersEdited(true);
  };

  /**
   * Set filter's vendor tags value
   * @param {*} event
   */
  const setVendorTagsHandler = (event) => {
    setVendorTags(event);
    setHasExecuted(false);
    setFiltersEdited(true);
  };

  /**
   * Set vendor campaign filter's tag value
   * @param {*} event
   */
  const setVendorCampaignTagsHandler = (event) => {
    setVendorCampaignTags(event);
    setHasExecuted(false);
    setFiltersEdited(true);
  };

  /**
   * Set filter's buyer tags value
   * @param {*} event
   */
  const setBuyerTagsHandler = (event) => {
    setBuyerTags(event);
    setHasExecuted(false);
    setFiltersEdited(true);
  };

  /**
   * Set buyer campaign filters tag value
   * @param {*} event
   */
  const setBuyerCampaignTagsHandler = (event) => {
    setBuyerCampaignTags(event);
    setHasExecuted(false);
    setFiltersEdited(true);
  };

  /**
   * Set event type filters tag value
   * @param {*} event
   */
  const setEventTypeTagsHandler = (event) => {
    setEventTypeTags(event);
    setHasExecuted(false);
    setFiltersEdited(true);
  };

  /**
   * Set filter's status tags value
   * @param {*} event
   */
  const setStatusTagsHandler = (event) => {
    setStatusTags(event);
    setHasExecuted(false);
    setFiltersEdited(true);
  };

  /**
   * Set duration filter tags value
   * @param {*} event
   */
  const setDurationTagsHandler = (event) => {
    setDurationTags(event);
    setHasExecuted(false);
    setFiltersEdited(true);
  };
  /**
   * Set filter's search tags value
   * @param {*} event
   */
  const setSearchTagsHandler = (event) => {
    setSearchTags(event);
    setHasExecuted(false);
    setFiltersEdited(true);
  };
  /**
   * Set filter's search tags value
   * @param {*} event
   */
  const setJanglIdTagsHandler = (event) => {
    setJanglIdTags(event);
    setHasExecuted(false);
    setFiltersEdited(true);
  };
  const [verticalSearch, setVerticalSearch] = useState("");
  const [buyerSearch, setBuyerSearch] = useState("");
  const [vendorSearch, setVendorSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [durationSearch, setDurationSearch] = useState("");
  const [tierSearch, setTierSearch] = useState("");
  const [buyerCampaignSearch, setBuyerCampaignSearch] = useState("");
  const [eventTypeSearch, setEventTypeSearch] = useState("");
  const [janglIdSearch, setJanglIdSearch] = useState("");

  const [vendorCampaignSearch, setVendorCampaignSearch] = useState("");
  const staticFilters = [
    {
      id: "event_name",
      label: t("EVENT"),
      type: "options",
      options: EVENT_TYPE_OPTIONS,
      search: eventTypeSearch,
      setSearch: setEventTypeSearch,
      setTagsHandler: setEventTypeTagsHandler,
      setTags: setEventTypeTags,
      tags: eventTypeTags,
      ref: eventTypeRef
    },
    {
      id: "status",
      label: t("STATUS"),
      type: "options",
      options: TAB_STATUS_OPTIONS,
      search: statusSearch,
      setSearch: setStatusSearch,
      setTags: setStatusTags,
      setTagsHandler: setStatusTagsHandler,
      tags: statusTags,
      ref: statusRef
    }
  ];
  const [filters, setFilters] = useState([
    {
      id: headCellVertical,
      label: t("VERTICAL"),
      type: "options",
      options: verticalOptions,
      search: verticalSearch,
      setSearch: setVerticalSearch,
      setTagsHandler: setVerticalTagsHandler,
      setTags: setVerticalTags,
      tags: verticalTags,
      ref: verticalRef
    },
    {
      id: "vertical_tier",
      label: t("VERTICAL_TIER"),
      type: "options",
      options: tierOptions,
      search: tierSearch,
      setSearch: setTierSearch,
      setTags: setTierTags,
      tags: tierTags,
      setTagsHandler: setTierTagsHandler,
      ref: verticalTierRef
    },
    {
      id: headCellVendor,
      label: t("VENDOR"),
      type: "options",
      options: vendorOptions,
      search: vendorSearch,
      setSearch: setVendorSearch,
      setTags: setVendorTags,
      setTagsHandler: setVendorTagsHandler,
      tags: vendorTags,
      ref: vendorRef
    },
    {
      id: "vendor_campaign",
      label: t("VENDOR_CAMPAIGN"),
      type: "options",
      options: vendorCampaignOptions,
      search: vendorCampaignSearch,
      setSearch: setVendorCampaignSearch,
      setTags: setVendorCampaignTags,
      setTagsHandler: setVendorCampaignTagsHandler,
      tags: vendorCampaignTags,
      ref: vendorCampiagnRef
    },
    {
      id: headCellBuyer,
      label: t("ENDPOINT"),
      type: "options",
      options: buyerOptions,
      search: buyerSearch,
      setSearch: setBuyerSearch,
      setTags: setBuyerTags,
      setTagsHandler: setBuyerTagsHandler,
      tags: buyerTags,
      ref: buyerRef
    },
    {
      id: "buyer_campaign",
      label: t("ENDPOINT_CAMPAIGN"),
      type: "options",
      options: buyerCampaignOptions,
      search: buyerCampaignSearch,
      setSearch: setBuyerCampaignSearch,
      setTags: setBuyerCampaignTags,
      setTagsHandler: setBuyerCampaignTagsHandler,
      tags: buyerCampaignTags,
      ref: buyerCampiagnRef
    },
    {
      id: headCellJanglId,
      label: t("JANGL_ID"),
      type: "free",
      options: [],
      search: janglIdSearch,
      setSearch: setJanglIdSearch,
      setTags: setJanglIdTags,
      setTagsHandler: setJanglIdTagsHandler,
      tags: janglIdTags,
      ref: janglIdRef
    },
    {
      id: "duration",
      label: t("DURATION"),
      type: "options",
      options: DURATION_OPTIONS,
      search: durationSearch,
      setSearch: setDurationSearch,
      setTags: setDurationTags,
      setTagsHandler: setDurationTagsHandler,
      tags: durationTags,
      ref: durationRef
    },
    {
      id: "search",
      label: t("SEARCH"),
      type: "free",
      options: [],
      search: searchInput,
      setSearch: setSearchInput,
      setTags: setSearchTags,
      setTagsHandler: setSearchTagsHandler,
      tags: searchTags,
      ref: searchRef
    },
  ]);

  const filterExists = (id) => {
    return filterComponents.some(function (el) {
      return el.id === id;
    });
  };

  const onFilterLogs = (row, headCell) => {
    const value = row[headCell.id];
    //check if filter is added already
    if (!filterExists(headCell.id)) {
      //add filter
      setFilterComponents(
        filterComponents.concat(filters.find((el) => el.id === headCell.id))
      );
    }
    //add value
    if (headCell.id === headCellJanglId) {
      const item = janglIdTags.find((item) => item.id === value);
      if (!janglIdTags.includes(item)) {
        setJanglIdTags(
          janglIdTags.concat({
            id: value,
            value: value,
            temp: true
          })
        );
        setHasExecuted(false);
        setFiltersEdited(true);
      }
    } else if (headCell.id === headCellVertical) {
      const item = verticalOptions.find((item) => item.id === row["vertical"]);
      if (item) {
        if (!verticalTags.includes(item)) {
          setVerticalTags(verticalTags.concat(item));
          setHasExecuted(false);
          setFiltersEdited(true);
        }
      } else {
        setVerticalTags(
          verticalTags.concat({
            id: row["vertical"],
            value: value
          })
        );
        setHasExecuted(false);
        setFiltersEdited(true);
      }
    } else if (headCell.id === headCellVendor) {
      const item = vendorOptions.find((item) => item.id === row["vendor_id"]);
      if (item) {
        if (!vendorTags.includes(item)) {
          setVendorTags(vendorTags.concat(item));
          setHasExecuted(false);
          setFiltersEdited(true);
        }
      } else {
        setVendorTags(
          vendorTags.concat({
            id: row["vendor_id"],
            value: value
          })
        );
        setHasExecuted(false);
        setFiltersEdited(true);
      }
    } else if (headCell.id === headCellBuyer) {
      const item = buyerOptions.find((item) => item.id === row["buyer_id"]);
      if (item) {
        if (!buyerTags.includes(item)) {
          setBuyerTags(buyerTags.concat(item));
          setHasExecuted(false);
          setFiltersEdited(true);
        }
      } else {
        setBuyerTags(
          buyerTags.concat({
            id: row["buyer_id"],
            value: value
          })
        );
        setHasExecuted(false);
        setFiltersEdited(true);
      }
    }

    staticFilters[0].tags = eventTypeTags;
    staticFilters[0].options = EVENT_TYPE_OPTIONS;
    staticFilters[1].tags = statusTags;
    staticFilters[1].options = TAB_STATUS_OPTIONS;

    filters[0].tags = verticalTags;
    filters[0].options = verticalOptions;
    filters[1].tags = tierTags;
    filters[1].options = tierOptions;
    filters[2].tags = vendorTags;
    filters[2].options = vendorOptions;
    filters[3].tags = vendorCampaignTags;
    filters[3].options = vendorCampaignOptions;
    filters[4].tags = buyerTags;
    filters[4].options = buyerOptions;
    filters[5].tags = buyerCampaignTags;
    filters[5].options = buyerCampaignOptions;
    filters[6].tags = janglIdTags;
    filters[6].options = [];
    filters[7].tags = durationTags;
    filters[7].options = DURATION_OPTIONS;
    filters[8].tags = searchTags;
    setFilters(filters);
  };
  const LOGS_HEAD_CELLS = [
    { id: "timestamp", label: "Timestamp" },
    {
      id: "event_name_formatted",
      label: <div style={{ margin: "5px" }}>{t("EVENT")}</div>,
      sortable: false
    },
    {
      id: headCellJanglId,
      label: "Jangl ID",
      showFilterIcon: true,
      filterHandler: onFilterLogs
    },
    {
      id: headCellVertical,
      label: "Vertical",
      showFilterIcon: true,
      filterHandler: onFilterLogs
    },
    {
      id: headCellVendor,
      label: "Vendor",
      showFilterIcon: true,
      filterHandler: onFilterLogs
    },
    {
      id: headCellBuyer,
      label: "Buyer",
      showFilterIcon: true,
      filterHandler: onFilterLogs
    },
    {
      id: headCellDuration,
      label: "Duration"
    },
    { id: "description", label: "Result" }
  ];

  //eye icon popup
  const [tabPopup, setTabPopUp] = useState(0);
  const [tabPopupResponse, setTabPopUpResponse] = useState(0);
  /**
   * Call vertical, buyer, vendor, source campaign
   * and buyer campaign filter options APIs
   */
  useEffect(() => {
    if (setting) {
      const fetchData = () => {
        onExecute();
        dispatch(verticalOperations.fetchVerticals(setting));
        dispatch(dataOperations.fetchBuyerNames());
        dispatch(dataOperations.fetchVendorNames());
        dispatch(dataOperations.fetchSourceCampaign(setting, allowCache));
        dispatch(dataOperations.fetchEndPointCampaign(setting, allowCache));
      };
      fetchData();
    }
  }, [dispatch, setting, allowCache]);

  /**
   * Set vertical Filter options
   */
  useEffect(() => {
    if (verticalDataWebLeads && setting === t("WEBLEADS_PARAM")) {
      setVerticalOptions(
        getFilterOptions(verticalDataWebLeads, t("SLUG"), t("NAME"))
      );

      setTierOptions(getTierOptions(verticalDataWebLeads));
    } else if (verticalDataCalls && setting === t("CALLS_PARAM")) {
      setVerticalOptions(
        getFilterOptions(verticalDataCalls, t("SLUG"), t("NAME"))
      );
      setTierOptions(getTierOptions(verticalDataCalls));
    }
  }, [verticalDataWebLeads, verticalDataCalls, setting, t]);

  /**
   * Set Buyer/Endpoint Filter options
   */
  useEffect(() => {
    if (
      buyerNamesData &&
      buyerNamesData.buyerNames &&
      buyerNamesData.buyerNames.length
    ) {
      setEndPointOptions(
        getFilterOptions(buyerNamesData.buyerNames, t("ID"), t("NAME"))
      );
    }
  }, [buyerNamesData, t]);

  /**
   * Set Vendor/Source Filter options
   */
  useEffect(() => {
    if (
      vendorNamesData &&
      vendorNamesData.vendorNames &&
      vendorNamesData.vendorNames.length
    ) {
      setSourceOptions(
        getFilterOptions(vendorNamesData.vendorNames, t("ID"), t("NAME"))
      );
    }
  }, [vendorNamesData, t]);

  /**
   * Set source/vendor campaign Filter options
   */
  useEffect(() => {
    if (vendorCampaignData && vendorCampaignData.length) {
      setVendorCampaignOptions(vendorCampaignData);
    }
  }, [vendorCampaignData]);

  /**
   * Set buyer/endpoint campaign Filter options
   */
  useEffect(() => {
    if (buyerCampaignData && buyerCampaignData.length) {
      setBuyerCampaignOptions(buyerCampaignData);
    }
  }, [buyerCampaignData]);

  /**
   * On change ping post logs data set value to pass in
   * children to show table data
   */
  useEffect(() => {
    if (pingPostLogsAPIResult) {
      setCursor("");
      setPingPostLogsData(pingPostLogsAPIResult);
    }
  }, [pingPostLogsAPIResult]);

  /**
   * View log function
   */
  const onViewLogs = (rowViewLogs) => {
    setRowViewLogs(rowViewLogs);
  };

  /**
   * Menu component
   */
  const menuItem = (rowViewLogs) => (
    <MenuItem
      className={classes.menu}
      onClick={(e) => {
        onViewLogs(rowViewLogs);
      }}
    >
      {t("VIEW_LOGS")}
    </MenuItem>
  );

  useEffect(() => {
    if (
      verticalTags.length ||
      buyerTags.length ||
      vendorTags.length ||
      statusTags.length ||
      searchTags.length ||
      tierTags.length ||
      buyerCampaignTags.length ||
      vendorCampaignTags.length ||
      eventTypeTags.length ||
      durationTags.length ||
      janglIdTags.length
    ) {
      setIsCursorSet(false);
      setDate(new Date());
    }
  }, [
    verticalTags,
    buyerTags,
    vendorTags,
    statusTags,
    searchTags,
    tierTags,
    buyerCampaignTags,
    vendorCampaignTags,
    eventTypeTags,
    durationTags,
    janglIdTags
  ]);

  useEffect(() => {
    if (!hasExecuted) {
      setHasExecuted(true);
    }
  }, [
    setting,
    cursor,
    verticalTags,
    buyerTags,
    vendorTags,
    statusTags,
    searchTags,
    tierTags,
    buyerCampaignTags,
    vendorCampaignTags,
    eventTypeTags,
    janglIdTags,
    durationTags,
    hasExecuted,
    filterComponents
  ]);

  /**
   * On Eye icon click
   * @param {*} row
   * @param {*} tabLogs if tablogs value is passed from
   * logs popup modal use that else tab value
   */
  const onEyeClick = (row) => {
    setRow(row);
    setOpenModal(true);
  };

  const onExecute = (cursorPage) => {
    const callBackFn = (response) => {
      if (response.next && !response.previous && !isCursorSet) {
        setFiltersEdited(true);
      }
    };

    const cursorLocal = cursorPage ? cursorPage : cursor;
    const queryParamLogs = getQueryParams(
      setting,
      cursorLocal,
      vendorTags,
      buyerTags,
      verticalTags,
      statusTags,
      durationTags,
      searchTags,
      tierTags,
      buyerCampaignTags,
      vendorCampaignTags,
      eventTypeTags,
      janglIdTags
    );
    dispatch(
      dataOperations.fetchPingPostLogs(
        setting,
        queryParamLogs,
        allowCache,
        callBackFn
      )
    );
    setHasExecuted(true);
    setFiltersEdited(false);
  };

  const closeHandler = () => {
    setOpenModal(false);
  };

  /**
   * Scroll to top when user clicks on the next or previous buttons.
   */
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  /**
   * Filters component
   */
  const tabContent = () => (
    <TabComponent
      staticFilters={staticFilters}
      filtersEdited={filtersEdited}
      setFiltersEdited={setFiltersEdited}
      onExecute={onExecute}
      filters={filters}
      setFilters={setFilters}
      //Options
      verticalOptions={verticalOptions}
      buyerOptions={buyerOptions}
      vendorOptions={vendorOptions}
      statusOptions={TAB_STATUS_OPTIONS}
      durationOptions={DURATION_OPTIONS}
      vendorCampaignOptions={vendorCampaignOptions}
      buyerCampaignOptions={buyerCampaignOptions}
      tierOptions={tierOptions}
      eventTypeOptions={EVENT_TYPE_OPTIONS}
      //Inputs
      date={date}
      setDate={setDate}
      verticalTags={verticalTags}
      setVerticalTags={setVerticalTags}
      buyerTags={buyerTags}
      setBuyerTags={setBuyerTags}
      vendorTags={vendorTags}
      setVendorTags={setVendorTags}
      statusTags={statusTags}
      setStatusTags={setStatusTags}
      searchTags={searchTags}
      setSearchTags={setSearchTags}
      durationTags={durationTags}
      setDurationTags={setDurationTags}
      tierTags={tierTags}
      setTierTags={setTierTags}
      janglIdTags={janglIdTags}
      setJanglIdTags={setJanglIdTags}
      buyerCampaignTags={buyerCampaignTags}
      setBuyerCampaignTags={setBuyerCampaignTags}
      vendorCampaignTags={vendorCampaignTags}
      setVendorCampaignTags={setVendorCampaignTags}
      eventTypeTags={eventTypeTags}
      setEventTypeTags={setEventTypeTags}
      data={pingPostLogsData}
      headCells={LOGS_HEAD_CELLS}
      setHasExecuted={setHasExecuted}
      onEyeClick={onEyeClick}
      setCursor={setCursor}
      showEllipseIcon={false}
      menuItem={menuItem}
      filterComponents={filterComponents}
      setFilterComponents={setFilterComponents}
      initialCursor={initialCursor}
      setInitialCursor={setInitialCursor}
      isCursorSet={isCursorSet}
      setIsCursorSet={setIsCursorSet}
      scrollToTop={scrollToTop}
    />
  );

  return (
    <div className={classes.dashboardMainDiv}>
      {row && (
        <CustomModal
          open={openModal}
          isTitle={false}
          closeHandler={closeHandler}
          theme={theme}
          closeOnEscape={true}
          closeOnBackdrop={true}
        >
          <Box component="div" className={classes.popupBox}>
            {modalLogs(
              setting,
              row,
              classes,
              t,
              tabPopup,
              setTabPopUp,
              tabPopupResponse,
              setTabPopUpResponse
            )}
          </Box>
        </CustomModal>
      )}
      <Grid container direction="column" className={classes.content}>
        {tabContent()}
        <Grid />
      </Grid>

      {rowViewLogs && (
        <LogsPopupModal
          rowViewLogs={rowViewLogs}
          setRowViewLogs={setRowViewLogs}
          allowCache={allowCache}
          setting={setting}
          onEyeClick={onEyeClick}
        />
      )}
    </div>
  );
};

export default Dashboard;
