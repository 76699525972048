import { combineReducers } from "redux";
import * as types from "./types";

const initialState = {
  records: {}
};

const initialStateVendor = {
  vendorNames: {}
};
const initialStateBuyer = {
  buyerNames: {}
};

const initialStateSourceCampaign = {
  records: []
};

const initialStateEndPointCampaign = {
  records: []
};

const vendorNamesReducer = (state = initialStateVendor, action) => {
  switch (action.type) {
    case types.FETCH_VENDOR_NAMES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const buyerNamesReducer = (state = initialStateBuyer, action) => {
  switch (action.type) {
    case types.FETCH_BUYER_NAMES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const sourceCampaignReducer = (state = initialStateSourceCampaign, action) => {
  switch (action.type) {
    case types.FETCH_SOURCE_CAMPAIGN_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const endPointCampaignReducer = (
  state = initialStateEndPointCampaign,
  action
) => {
  switch (action.type) {
    case types.FETCH_ENDPOINT_CAMPAIGN_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const pingPostLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PING_POST_LOGS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const pingPostViewLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PING_POST_VIEW_LOGS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const reducer = combineReducers({
  vendorNames: vendorNamesReducer,
  buyerNames: buyerNamesReducer,
  sourceCampaign: sourceCampaignReducer,
  endPointCampaign: endPointCampaignReducer,
  pingPostLogs: pingPostLogsReducer,
  pingPostViewLogs: pingPostViewLogsReducer
});

export default reducer;
