import { loaderOperations } from "../loader";
import { alertOperations } from "../alerts";
import {
  fetchInboundLogsByIdAPI,
  fetchVendorNamesAPI,
  fetchBuyersNamesAPI,
  fetchBuyerNamesAPI,
  fetchEndpointCampaignAPI,
  fetchSourceCampaignAPI,
  fetchPingPostLogsAPI
} from "../../services/api";
import * as actions from "./actions";

const fetchVendorNames = (allowCache, showLoading = false) => {
  return async (dispatch) => {
    try {
      showLoading && dispatch(loaderOperations.uiStartLoading());
      const response = await fetchVendorNamesAPI(allowCache);
      dispatch(
        actions.fetchVendorNameSuccess({
          vendorNames: response.data
        })
      );
      showLoading && dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(alertOperations.addAlert(err.message, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

const fetchBuyerNames = (allowCache, showLoading = false) => {
  return async (dispatch) => {
    try {
      showLoading && dispatch(loaderOperations.uiStartLoading());
      const response = await fetchBuyersNamesAPI(allowCache);
      dispatch(
        actions.fetchBuyerNameSuccess({
          buyerNames: response.data
        })
      );
      showLoading && dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(alertOperations.addAlert(err.message, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

//Todo
const fetchBuyerName = (id) => {
  return async (dispatch) => {
    try {
      dispatch(loaderOperations.uiStartLoading());
      const response = await fetchBuyerNamesAPI(id);
      dispatch(
        actions.fetchBuyerNameSuccess({
          buyerName: response.data
        })
      );
      dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(alertOperations.addAlert(err.message, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

const fetchInboundLogsByID = (params, queryParams, callBackFn, allowCache) => {
  return async (dispatch) => {
    try {
      dispatch(loaderOperations.uiStartLoading());
      const response = await fetchInboundLogsByIdAPI(
        params,
        queryParams,
        allowCache
      );
      dispatch(
        actions.fetchIdSuccessLogs({
          log: response.data
        })
      );
      if (callBackFn) {
        callBackFn(response.data);
      }
      dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(alertOperations.addAlert(err.message, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

/**
 * Source campaign for filters
 * @param {*} params
 * @param {*} allowCache
 */
const fetchSourceCampaign = (params, allowCache, showLoading = false) => {
  return async (dispatch) => {
    try {
      showLoading && dispatch(loaderOperations.uiStartLoading());
      const response = await fetchSourceCampaignAPI(params, allowCache);
      dispatch(
        actions.fetchSourceCampaignSuccess({
          records: response.data
        })
      );
      showLoading && dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(alertOperations.addAlert(err.message, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

/**
 * Endpoint campaign for filters
 * @param {*} params
 * @param {*} allowCache
 */
const fetchEndPointCampaign = (params, allowCache,showLoading = false) => {
  return async (dispatch) => {
    try {
      showLoading && dispatch(loaderOperations.uiStartLoading());
      const response = await fetchEndpointCampaignAPI(params, allowCache);
      dispatch(
        actions.fetchEndPointCampaignSuccess({
          records: response.data
        })
      );
      showLoading && dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(alertOperations.addAlert(err.message, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

/**
 * Fetch ping post logs data from API
 * @param {*} params
 * @param {*} queryParams
 * @param {*} apiURL
 * @param {*} allowCache
 */
const fetchPingPostLogs = (params, queryParams, allowCache,callBackFn) => {
  return async (dispatch) => {
    try {
      dispatch(loaderOperations.uiStartLoading());
      const response = await fetchPingPostLogsAPI(
        params,
        queryParams,
        allowCache
      );
      dispatch(
        actions.fetchPingPostLogsSuccess({
          records: response.data
        })
      );
      if (callBackFn) {
        callBackFn(response.data);
      }
      dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(alertOperations.addAlert(err.message, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

/**
 * Fetch ping post logs view data from API
 * @param {*} params
 * @param {*} queryParams
 * @param {*} apiURL
 * @param {*} allowCache
 */
const fetchPingPostViewLogs = (
  reset,
  params,
  queryParams,
  apiURL,
  allowCache
) => {
  return async (dispatch) => {
    try {
      dispatch(loaderOperations.uiStartLoading());
      let response = {};
      if (!reset) {
        response = await fetchPingPostLogsAPI(
          params,
          queryParams,
          apiURL,
          allowCache
        );
      }
      dispatch(
        actions.fetchPingPostViewLogsSuccess({
          records: !reset ? response.data : []
        })
      );
      dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(alertOperations.addAlert(err.message, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

export {
  fetchBuyerNames,
  fetchVendorNames,
  fetchBuyerName,
  fetchInboundLogsByID,
  fetchSourceCampaign,
  fetchEndPointCampaign,
  fetchPingPostLogs,
  fetchPingPostViewLogs
};
