import * as types from "./types";
import { combineReducers } from "redux";

const initialState = {
  access_token: localStorage.getItem("access_token")
    ? localStorage.getItem("access_token")
    : null,
  refresh_token: localStorage.getItem("refresh_token")
    ? localStorage.getItem("refresh_token")
    : null,
  isLoggedIn: localStorage.getItem("isLoggedIn")
    ? localStorage.getItem("isLoggedIn")
    : false,
  path: localStorage.getItem("path") ? localStorage.getItem("path") : null
};

const userState = {
  userEmail: localStorage.getItem("userEmail")
    ? localStorage.getItem("userEmail")
    : null
};

const userInfoState = {};

const siteState = {
  record: null
};

const reducerAuth = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_AUTH_SUCCESS:
      return {
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
        isLoggedIn: true,
        path: localStorage.getItem("path")
      };
    default:
      return state;
  }
};

const reducerUser = (state = userState, action) => {
  switch (action.type) {
    case types.FETCH_USER_SUCCESS:
      return {
        userEmail: action.payload.email
      };
    default:
      return state;
  }
};

const reducerUserInfo = (state = userInfoState, action) => {
  switch (action.type) {
    case types.FETCH_USER_INFO_SUCCESS:
      return {
        permissions:
          action.payload.vendors &&
          action.payload.vendors[0] &&
          action.payload.vendors[0].permissions
            ? action.payload.vendors[0].permissions
            : [],
        vendorUser:
          action.payload.vendors && action.payload.vendors.length ? true : false
      };
    default:
      return state;
  }
};

const reducerSiteInfo = (state = siteState, action) => {
  switch (action.type) {
    case types.FETCH_SITE_INFO_SUCCESS:
      return {
        record: action.payload
      };
    default:
      return state;
  }
};

const reducer = combineReducers({
  user: reducerUser,
  auth: reducerAuth,
  siteInfo: reducerSiteInfo,
  userInfo: reducerUserInfo
});

export default reducer;
