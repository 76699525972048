import { callRESTAPI } from "./apiHandler";

// default api base url
const baseURL = "/api/v1";

export const getSiteInfo = () =>
  callRESTAPI(baseURL + "/accounts/site/", "get");
export const getUser = () => callRESTAPI(baseURL + "/accounts/user/", "get");
export const getCsrfTokenAPI = () => {
  return callRESTAPI(baseURL + "/csrf-token/", "get");
};

export const fetchVerticalsAPI = (params) =>
  callRESTAPI(baseURL + "/" + params + "/verticals/", "get", null);
export const fetchVerticalBySlugAPI = (slug) =>
  callRESTAPI(baseURL + "/webleads/verticals/" + slug + "/", "get", null);

export const fetchVendorNamesAPI = () =>
  callRESTAPI(baseURL + "/accounts/vendors/names/", "get", null);

export const fetchBuyersNamesAPI = () =>
  callRESTAPI(baseURL + "/accounts/buyers/names/", "get", null);
export const fetchBuyerNamesAPI = (id) =>
  callRESTAPI(baseURL + "/accounts/buyers/names/" + id, "get", null);

export const fetchInboundLogsByIdAPI = (params, id, allowCache) =>
  callRESTAPI(
    baseURL + "/logs/" + params + "/" + id,
    "get",
    null,
    null,
    allowCache
  );

export const fetchEndpointCampaignAPI = (params, allowCache) =>
  callRESTAPI(
    baseURL + "/" + params + "/buyer_campaigns/",
    "get",
    null,
    null,
    allowCache
  );

export const fetchSourceCampaignAPI = (params, allowCache) =>
  callRESTAPI(
    baseURL + "/" + params + "/vendor_campaigns/",
    "get",
    null,
    null,
    allowCache
  );
export const fetchPingPostLogsAPI = (params, queryParams, allowCache) =>
  callRESTAPI(
    baseURL + "/logs/" + params + "/" + queryParams,
    "get",
    null,
    null,
    allowCache
  );
