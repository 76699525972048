import React from "react";

import { createSelector } from "reselect";
import {
  logColor,
  logTypeLabel
} from "../../Constants";
import { Typography } from "@material-ui/core";
import { formatDateTime } from "../../containers/Dashboard/LogsUtils";


const featureSelector = ({ leads }) => leads;

export const getBuyerNamesData = createSelector(
  featureSelector,
  ({ buyerNames }) => buyerNames
);

export const getVendorNamesData = createSelector(
  featureSelector,
  ({ vendorNames }) => vendorNames
);

export const getSourceCampaignData = createSelector(
  featureSelector,
  ({ sourceCampaign }) =>
    sourceCampaign?.records?.map((row) => ({
      id: row.id,
      value: row.id + " - " + row.display_name
    }))
);

export const getEndPointCampaignData = createSelector(
  featureSelector,
  ({ endPointCampaign }) =>
    endPointCampaign?.records?.map((row) => ({
      id: row.id,
      value: row.id + " - " + row.display_name
    }))
);

const formatLogsData = (logsData) => {
  if (
    logsData?.records?.results?.length
  ) {
    logsData.records.results.forEach((log) => {
      if (!log.timestamp.includes(" - ")) {
        log.timestamp = formatDateTime(log.timestamp);
      }
      if (log.event_name && !log.event_name_formatted){// && !log.event_name_formatted.toString().includes(logTypeLabel)) {
        log.event_name_formatted = (
          <Typography
            style={{
              background: logColor[log.log_type],
              textAlign: "center",
              color: "white",
              borderRadius: "8px",
              textTransform: "capitalize",
              paddingRight: "3px",
              paddingLeft: "3px",
              fontSize: "14px"
            }}
          >
            {log.event_name}
          </Typography>
        );
      }
    });
  }
  return logsData.records;
};

export const getPingPostLogsData = createSelector(
  featureSelector,
  ({ pingPostLogs }) => {
    return formatLogsData(pingPostLogs);
  }
);

export const getPingPostViewLogsData = createSelector(
  featureSelector,
  ({ pingPostViewLogs }) => {
    return formatLogsData(pingPostViewLogs);
  }
);
