import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { alertSelectors, alertOperations } from '../../store/alerts';
import useStyles from "./Notifications.styles";

/**
 * Component to show app level notifications
 */
export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alerts = useSelector(alertSelectors.getAlerts);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (!alerts.length) {
      closeSnackbar();
    }

    alerts.forEach(({ key, message, type }) => {
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        autoHideDuration: 5000,
        key: key,
        variant: type || 'default',
        className: classes.notification,
        action: key => (
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            style={{
              padding: 0
            }}
            onClick={() => {
              closeSnackbar(key);
              dispatch(alertOperations.removeAlert(key));
            }}
          >
            <CloseIcon />
          </IconButton>
        ),
        onClose: (event, reason, key) => {
          if (reason === 'clickaway') return;

          // Dispatch action to remove alert from redux store
          dispatch(alertOperations.removeAlert(key));
        }
      });
    });
  }, [alerts, closeSnackbar, enqueueSnackbar, dispatch, classes.notification]);

  return null;
};
