const FETCH_AUTH_SUCCESS = "jangl/auth/FETCH_AUTH_SUCCESS";
const FETCH_USER_SUCCESS = "jangl/auth/FETCH_USER_SUCCESS";
const FETCH_SITE_INFO_SUCCESS = "jangl/auth/FETCH_SITE_INFO_SUCCESS";
const FETCH_USER_INFO_SUCCESS = "jangl/auth/FETCH_USER_INFO_SUCCESS";

export {
  FETCH_AUTH_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_SITE_INFO_SUCCESS,
  FETCH_USER_INFO_SUCCESS
};
