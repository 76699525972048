import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  notification: {
    "& .MuiSnackbarContent-root": {
      top: "146px",
      left: "1271px",
      width: "fit-content",
      height: "42px",
      color: theme.palette.primary.main,
      background: theme.palette.primary.white + " 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 60px #00000029",
      border: "3px solid " + theme.palette.primary.main,
      borderRadius: "5px",
      opacity: "1"
    }
  },
  close: {
    padding: theme.spacing(0.5)
  }
}));
