import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { TagsAutocomplete } from "jangl-common-js";
import useStyles from "./Filters.style";
import theme from "../../CustomTheme";
import CloseIcon from "@material-ui/icons/Close";

const FilterComponent = (props) => {
  const {
    //Options Arrays
    options,
    //Inputs
    tags,
    search,
    setSearch,
    type,
    id,
    label,
    removeItem,
    setTagsHandler ,
    inputRef,
    canRemove = true
   } = props;
  const classes = useStyles();

  return (
    <Grid item className={classes.filterDynamic}>
      <Grid>
        <Grid container direction="row" justify="space-between">
          <Typography className={classes.filterText}>{label}</Typography>
         {canRemove && ( <CloseIcon
            className={classes.closeButton}
            onClick={() => removeItem(id)}
          />)}
        </Grid>
        <TagsAutocomplete
          id={id}
          dataOptions={options || []}
          freeSolo={type === "options" ? false : true}
          tags={tags}
          setTags={setTagsHandler}
          openOnFocus={true}
          search={search}
          setSearch={setSearch}
          filterSelectedOptions={true}
          variant="outlined"
          styleOverride={classes.filters}
          placeholder=""
          theme={theme}
          inputRef={inputRef}
        />
      </Grid>
    </Grid>
  );
};

export default FilterComponent;
