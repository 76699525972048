import React, { useRef, useEffect } from "react";
import handyScroll from "handy-scroll";

export default function HandyScroll(props) {
  let firstDiv = useRef();

  useEffect(() => {
    handyScroll.mount(firstDiv.firstElementChild);
  });

  return <div ref={(el) => (firstDiv = el)}>{props.children}</div>;
}
