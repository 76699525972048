import React from "react";
import "./App.css";

import { COLOR_OPTIONS } from "./Constants";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import themeDefault from "./CustomTheme.js";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Loading from "./containers/Backdrop/BackDrop";
import Notifications from "./containers/Notifications/Notifications";
import { SnackbarProvider } from "notistack";
import { Slide } from "@material-ui/core";
import CustomRoutes from "./containers/CustomRoutes/CustomRoutes";

function App() {
  const { t } = useTranslation();
  const history = createBrowserHistory();

  /**
   * Returns site name of window jangl object
   */
  const getTitle = () => {
    return window[t("WINDOW_JANGL_OBJECT")].site_name + t("JANGL_SUBTITLE");
  };

  const setSiteSettings = () => {
    const colorVal = window[t("WINDOW_JANGL_OBJECT")].theme;
    const theme = COLOR_OPTIONS[colorVal]
      ? COLOR_OPTIONS[colorVal]
      : COLOR_OPTIONS["orange"];
    themeDefault.palette.primary.main = theme;
    localStorage.setItem("theme", theme);
    document.title = getTitle();
  };
  setSiteSettings();

  const theme = createMuiTheme(themeDefault);

  const routes = () => {
    return (
      <Router history={history}>
        <CustomRoutes />
        <Loading />
      </Router>
    );
  };

  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider
        preventDuplicate
        maxSnack={10}
        TransitionComponent={TransitionUp}
        className="customAlert"
      >
        <div className="spa-app">
          <div className="spa-content">
            <div className="routes-container">{routes()}</div>
          </div>
          
          <Notifications />
        </div>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default App;
