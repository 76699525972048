const FETCH_LOGS_ID_SUCCESS = "jangl/logs/FETCH_LOGS_ID_SUCCESS";
const FETCH_VENDOR_NAMES_SUCCESS = "jangl/call/FETCH_VENDOR_NAMES_SUCCESS";
const FETCH_BUYER_NAMES_SUCCESS = "jangl/call/FETCH_BUYER_NAMES_SUCCESS";

const FETCH_SOURCE_CAMPAIGN_SUCCESS =
  "jangl/source/campaign/FETCH_SOURCE_CAMPAIGN_SUCCESS";

const FETCH_ENDPOINT_CAMPAIGN_SUCCESS =
  "jangl/endpoint/campaign/FETCH_ENDPOINT_CAMPAIGN_SUCCESS";

const FETCH_PING_POST_LOGS_SUCCESS =
  "jangl/ping_post_logs/FETCH_PING_POST_LOGS_SUCCESS";

const FETCH_PING_POST_VIEW_LOGS_SUCCESS =
  "jangl/ping_post_logs/FETCH_PING_POST_VIEW_LOGS_SUCCESS";

export {
  FETCH_LOGS_ID_SUCCESS,
  FETCH_VENDOR_NAMES_SUCCESS,
  FETCH_BUYER_NAMES_SUCCESS,
  FETCH_SOURCE_CAMPAIGN_SUCCESS,
  FETCH_ENDPOINT_CAMPAIGN_SUCCESS,
  FETCH_PING_POST_LOGS_SUCCESS,
  FETCH_PING_POST_VIEW_LOGS_SUCCESS
};
