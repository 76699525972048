import * as types from "./types";

const fetchVendorNameSuccess = (vendorNames) => ({
  type: types.FETCH_VENDOR_NAMES_SUCCESS,
  payload: vendorNames
});

const fetchBuyerNameSuccess = (buyerNames) => ({
  type: types.FETCH_BUYER_NAMES_SUCCESS,
  payload: buyerNames
});

const fetchIdSuccessLogs = (log) => ({
  type: types.FETCH_LOGS_ID_SUCCESS,
  payload: log
});

const fetchSourceCampaignSuccess = (record) => ({
  type: types.FETCH_SOURCE_CAMPAIGN_SUCCESS,
  payload: record
});

const fetchEndPointCampaignSuccess = (record) => ({
  type: types.FETCH_ENDPOINT_CAMPAIGN_SUCCESS,
  payload: record
});

const fetchPingPostLogsSuccess = (pingPostLogs) => ({
  type: types.FETCH_PING_POST_LOGS_SUCCESS,
  payload: pingPostLogs
});

const fetchPingPostViewLogsSuccess = (pingPostViewLogs) => ({
  type: types.FETCH_PING_POST_VIEW_LOGS_SUCCESS,
  payload: pingPostViewLogs
});

export {
  fetchVendorNameSuccess,
  fetchBuyerNameSuccess,
  fetchIdSuccessLogs,
  fetchSourceCampaignSuccess,
  fetchEndPointCampaignSuccess,
  fetchPingPostLogsSuccess,
  fetchPingPostViewLogsSuccess
};
