import axios from "axios";
import i18n from "../i18n";

/**
 * Set "X-CSRFToken" header to save/update/delete data.
 * @param {*} headers
 * @param {*} method
 */
const setCSRFTokenHeader = (headers, method) => {
  if (
    ["post", "put", "patch", "delete"].includes(method) &&
    document &&
    document.cookie
  ) {
    const csrftokenCookie = document.cookie
      .split(";")
      .find((value) => value.includes("csrftoken"));
    if (csrftokenCookie) {
      headers["X-CSRFToken"] = csrftokenCookie.split("=")[1];
    }
  }
};
/**
 * Set "X-No-Cache" header to get updated data from the API only for get method
 * @param {*} headers
 * @param {*} method
 */
const setXNoCacheHeaderGetMethod = (headers, method) => {
  if (method === "get") {
    headers["X-No-Cache"] = 1;
  }
};

/**
 *
 * @param {string} url endpoint url to attach with base url
 * @param {string} method method to call rest api: get, post, put
 * @param {object} data object to be send with rest api as post data
 * @param {boolean} auth if true jwt token will be attached to authentication header
 */
export const callRESTAPI = async (
  url,
  method = "get",
  data = null,
  params = null,
  allowCache = null,
  auth = true,
  responseType = null,
  headers = {
    "X-REQUESTED-WITH": "XMLHttpRequest"
  }
) => {
  try {
    setCSRFTokenHeader(headers, method);
    if (!allowCache) {
      setXNoCacheHeaderGetMethod(headers, method);
    }

    return await axios({
      url,
      params,
      method,
      data,
      responseType,
      headers
    });
  } catch (error) {
    if (!error.response) {
      if (error.message === "Network Error") {
        error.message = i18n.t("403_ERROR_MESSAGE");
      }
      throw error;
    }
    // handle different error response status
    if (error && error.response && [401, 403].includes(error.response.status)) {
      error.message = null;
    } else if (error && error.response && error.response.status === 404) {
      error.message = i18n.t("404_ERROR_MESSAGE");
    } else if (error && error.response && error.response.status === 409) {
      error.message = i18n.t("409_ERROR_MESSAGE");
    } else if (error && error.response && error.response.status === 500) {
      error.message = i18n.t("500_ERROR_MESSAGE");
    }
    throw error;
  }
};
