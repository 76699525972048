import { loaderOperations } from "../loader";
import { alertOperations } from "../alerts";
import { fetchVerticalsAPI, fetchVerticalBySlugAPI } from "../../services/api";
import * as actions from "./actions";

/**
 * Fetch vertical list data
 * @param {*} params
 */
const fetchVerticals = (params, callBackFn = null) => {
  return async (dispatch) => {
    try {
      dispatch(loaderOperations.uiStartLoading());
      const response = await fetchVerticalsAPI(params);
      dispatch(
        actions.fetchListSuccess({
          records: response.data
        })
      );
      if (callBackFn) {
        callBackFn(response.data.length);
      }
      dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(alertOperations.addAlert(err.message, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

/**
 * WEBLEADS fetch vertical by Slug
 * @param {*} params
 */
const fetchVerticalBySlug = (params, callBackFn = null, showLoading = false) => {
  return async (dispatch) => {
    try {
      showLoading && dispatch(loaderOperations.uiStartLoading());
      if (params) {
        const response = await fetchVerticalBySlugAPI(params);

        if (callBackFn) {
          callBackFn(response.data);
        }
      }
      showLoading && dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(alertOperations.addAlert(err.message, "error"));
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};
export { fetchVerticals, fetchVerticalBySlug };
