import React from "react";

export const DATE_FORMAT = "yyyy-MM-DD";
export const DATE_FORMAT_MM_DD_YYYY = "MM/DD/YYYY";
export const DATE_TIME_FORMAT_YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_FORMAT_YYYY_MM_DD_T_HH_MM_SS = "YYYY-MM-DDTHH:mm:ss";
export const DATE_TIME_FORMAT_MMM_D_H_MMA = "MMM D - h:mm:ss a";
export const DATE_TIME_FORMAT_M_D_YY_H_MMA = "M/D/YY - h:mm:ss a";

export const COLOR_OPTIONS = {
  grey: "#CCCCCC",
  blue: "#2765a0",
  equoto: "#f10b57",
  godirectauto: "#af1d20",
  goldrock: "#dd9821",
  green: "#56b12c",
  groupm: "#0080ff",
  icw: "#0098c0",
  iheart: "#c6002b",
  ilovegoodleads: "#c52f2c",
  jmadvertising: "#0092d6",
  leadrealm: "#1e7351",
  orange: "#ff8022",
  pentius: "#007cc3",
  platinum: "#1b92e3",
  purple: "#673e98",
  quinstreet: "#ff4300",
  red: "#fc2024",
  refined: "#ec4b2b",
  transonic: "#1c1b54"
};

export const EVENT_TYPE_OPTIONS = [
  { id: "ping", value: "Ping" },
  { id: "post", value: "Post" },
  { id: "direct_post", value: "Direct Post" },
  { id: "capture", value: "Capture" },
  { id: "inbound", value: "Inbound" },
  { id: "outbound", value: "Outbound" },
  { id: "endpoint", value: "Endpoint" },
  { id: "verify", value: "Verify" },
  { id: "webhook", value: "Webhook" }
];

export const TAB_STATUS_OPTIONS = [
  { id: "success", value: "Success" },
  { id: "duplicate", value: "Duplicate" },
  { id: "no_buyers", value: "No Buyers" },
  { id: "validation", value: "Invalid" },
  { id: "exception", value: "Exception" },
  { id: "test", value: "Test" },
  { id: "prod", value: "Production" },
  { id: "error", value: "Error" }
];

export const DURATION_OPTIONS = [
  { id: "under_2_seconds", value: "Under 2 seconds" },
  { id: "under_5_seconds", value: "Under 5 seconds" },
  { id: "over_5_seconds", value: "Over 5 seconds" },
  { id: "over_10_seconds", value: "Over 10 seconds" }
];

export const LOGS_HEAD_CELLS = [
  { id: "timestamp", label: "Timestamp" },
  {
    id: "event_name",
    label: <div style={{ margin: "5px" }}>{"Event"}</div>,
    sortable: false
  },
  { id: "jangl_id", label: "Jangl ID", showFilterIcon: true },
  { id: "vertical_name", label: "Vertical" },
  { id: "vendor_name", label: "Vendor" },
  { id: "buyer_name", label: "Buyer" },
  { id: "description", label: "Result" }
];

export const logColor = {
  inbound: "#6077a6",
  outbound: "#f28705",
  endpoint: "#f2b705",
  verify: "#c0d904",
  webhook: "#6dccf2"
};

export const logTypeLabel = "Typography";
export const headCellJanglId = "jangl_id";
export const headCellVertical = "vertical_name";

export const headCellBuyer = "buyer_name";
export const headCellVendor = "vendor_name";
export const headCellDuration = "duration";
