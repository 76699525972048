import * as types from "./types";

/**
 * Fetch List Success for Vertical List Data
 * @param {*} data
 */
const fetchListSuccess = (data) => ({
  type: types.FETCH_LIST_SUCCESS,
  payload: data
});
export { fetchListSuccess };
