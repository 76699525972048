import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  filterText: {
    textAlign: "start",
    fontSize: "1.5rem !important"
  },

  filterTextDate: {
    textAlign: "start",
    fontSize: "1.5rem !important",
    marginBottom: "8px",
    "& + div input": {
      padding: "10px"
    }
  },
  filters: {
    fullWidth: true,
    width: "100%",
    "& > * + *": {
      marginTop: 0
    }
  },
  /*Filters css*/
  secondRowFilter: {
    paddingTop: "10px"
  },
  advanceFiltersDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  advanceFilters: {
    font: "normal normal 20px/24px Ubuntu",
    letterSpacing: "0px",
    color: theme.palette.primary.main,
    padding: "20px 10px"
  },
  addButton: {
    marginTop: "20px",
    marginRight:"20px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white
    }
  },
  iconButton: {
    marginTop: "20px",
    marginRight:"20px"
  },
  iconLarge:{fontSize: 35 },
  buttonDiv:{
    float: "right",
  },
  filterDynamic: { width: "19vw" },
  closeButton: {
    marginTop: "3px",
    cursor: "pointer",
    color: theme.palette.primary.lightBlack
  },
  datefieldstyle: {
    width: "100%",
    "&>div": {
      height: "39px"
    }
  },
  filterDiv: {
    maxWidth: "82%",
    width: "fit-content"
  },
  iconBtn: {
    width: "18%",
    paddingTop: "16px !important"
  },
  btnDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap"
  }
}));
