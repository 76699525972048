import { combineReducers } from "redux";
import * as types from "./types";

const initialState = {
  records: []
};

/**
 * Vertical reducer
 * @param {*} state
 * @param {*} action
 */
const listReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_LIST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const reducer = combineReducers({
  list: listReducer
});

export default reducer;
