import React from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Filters from "./Filters";
import { GridTable } from "jangl-common-js";
import HandyScroll from "../../components/UI/HandyScroll/HandyScroll";
import theme from "../../CustomTheme";
import useStyles from "./Dashboard.style";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const TabComponent = (props) => {
  const {
    //Options Arrays
    verticalOptions,
    buyerOptions,
    vendorOptions,
    statusOptions,
    durationOptions,
    tierOptions,
    buyerCampaignOptions,
    vendorCampaignOptions,
    eventTypeOptions,
    logTypeOptions,
    //Variables
    isTier = true,
    isVendor = true,
    isVendorCampaign = true,
    isBuyer = true,
    isBuyerCampaign = true,

    //Inputs
    date,
    setDate,
    verticalTags,
    setVerticalTags,
    buyerTags,
    setBuyerTags,
    vendorTags,
    setVendorTags,
    statusTags,
    setStatusTags,
    searchTags,
    setSearchTags,
    durationTags,
    setDurationTags,
    tierTags,
    setTierTags,
    janglIdTags,
    setJanglIdTags,
    buyerCampaignTags,
    setBuyerCampaignTags,
    vendorCampaignTags,
    setVendorCampaignTags,
    eventTypeTags,
    setEventTypeTags,
    logTypeTags,
    setLogTypeTags,
    data,
    headCells,
    setHasExecuted,
    onEyeClick,
    setCursor,
    showEllipseIcon,
    menuItem,
    staticFilters,
    filterComponents,
    setFilterComponents,
    filters,
    setFilters,
    initialCursor,
    setInitialCursor,
    isCursorSet,
    setIsCursorSet,
    onExecute,
    filtersEdited,
    setFiltersEdited,
    scrollToTop
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Filters
        staticFilters={staticFilters}
        filtersEdited={filtersEdited}
        setFiltersEdited={setFiltersEdited}
        onExecute={onExecute}
        filters={filters}
        setFilters={setFilters}
        //Options Arrays
        verticalOptions={verticalOptions}
        buyerOptions={buyerOptions}
        vendorOptions={vendorOptions}
        statusOptions={statusOptions}
        durationOptions={durationOptions}
        vendorCampaignOptions={vendorCampaignOptions}
        buyerCampaignOptions={buyerCampaignOptions}
        tierOptions={tierOptions}
        eventOptions={eventTypeOptions}
        logTypeOptions={logTypeOptions}
        //Variables to show hide filters
        isTier={isTier}
        isVendor={isVendor}
        isVendorCampaign={isVendorCampaign}
        isBuyer={isBuyer}
        isBuyerCampaign={isBuyerCampaign}
        //Inputs
        date={date}
        setDate={setDate}
        verticalTags={verticalTags}
        setVerticalTags={setVerticalTags}
        buyerTags={buyerTags}
        setBuyerTags={setBuyerTags}
        vendorTags={vendorTags}
        setVendorTags={setVendorTags}
        statusTags={statusTags}
        setStatusTags={setStatusTags}
        searchTags={searchTags}
        setSearchTags={setSearchTags}
        durationTags={durationTags}
        setDurationTags={setDurationTags}
        tierTags={tierTags}
        setTierTags={setTierTags}
        buyerCampaignTags={buyerCampaignTags}
        setBuyerCampaignTags={setBuyerCampaignTags}
        vendorCampaignTags={vendorCampaignTags}
        setVendorCampaignTags={setVendorCampaignTags}
        eventTypeTags={eventTypeTags}
        setEventTypeTags={setEventTypeTags}
        logTypeTags={logTypeTags}
        setLogTypeTags={setLogTypeTags}
        janglIdTags={janglIdTags}
        setJanglIdTags={setJanglIdTags}
        //other
        setHasExecuted={setHasExecuted}
        setCursor={setCursor}
        filterComponents={filterComponents}
        setFilterComponents={setFilterComponents}
        initialCursor={initialCursor}
        setInitialCursor={setInitialCursor}
        isCursorSet={isCursorSet}
        setIsCursorSet={setIsCursorSet}
      />
      {data.results && ( //to make sure table is mounted only once
        <HandyScroll>
          <GridTable
            theme={theme}
            data={data.results || []}
            headCells={headCells}
            showEyeIcon={true}
            onEyeClick={onEyeClick}
            showEllipseIcon={showEllipseIcon}
            textColor={theme.palette.primary.headerText}
            pagination={false}
            sortable={true}
            menuItem={menuItem}
            size={"small"}
            equalWidthColumns={false}
            publicURL={""}
            enableRowClick={true}
          />
        </HandyScroll>
      )}
      <div className={classes.buttonDiv}>
        <Button
          onClick={() => {
            setCursor(data.previous);
            setInitialCursor(data.previous);
            setHasExecuted(false);
            setIsCursorSet(false);
            onExecute(data.previous);
            scrollToTop();
          }}
          variant="outlined"
          color="primary"
          className={classes.button}
          disabled={!data.previous}
          startIcon={
            <ArrowBackIcon
              className={!data.previous ? classes.iconDisabled : classes.icon}
            />
          }
        >
          {t("NEWER")}
        </Button>
        <Button
          onClick={() => {
            setCursor(data.next);
            setInitialCursor(data.next);
            setHasExecuted(false);
            setIsCursorSet(false);
            onExecute(data.next);
            scrollToTop();
          }}
          variant="outlined"
          color="primary"
          disabled={!data.next}
          className={classes.button}
          endIcon={
            <ArrowForwardIcon
              className={!data.next ? classes.iconDisabled : classes.icon}
            />
          }
        >
          {t("OLDER")}
        </Button>
      </div>
    </>
  );
};

export default TabComponent;
