import { extendMoment } from "moment-range";
import originalMoment from "moment";
import i18n from "../../i18n";
import { DATE_TIME_FORMAT_YYYY_MM_DD_T_HH_MM_SS } from "../../Constants";
import {
  DATE_TIME_FORMAT_MMM_D_H_MMA,
  DATE_TIME_FORMAT_M_D_YY_H_MMA
} from "../../Constants";
const moment = extendMoment(originalMoment);

/**
 * Get Tag string
 * @param {*} list
 */
export const getTagString = (list) => {
  let tagString = "";
  Object.entries(list).forEach((item) => {
    if (tagString.length > 0) {
      tagString += "%2C";
    }
    tagString += item[1].id;
  });
  return tagString;
};

/**
 *
 * @param {*} list
 * @param {*} tab
 */
export const getStatusTagString = (list, tab) => {
  let tagString = "";
  if ([2, 3].includes(tab) && list.length === 2) {
    return tagString;
  }
  Object.entries(list).forEach((item) => {
    tagString += "&" + item[1].id;
  });
  return tagString;
};

/**
 * Get cursor date values for first and second tabs
 * @param {*} dateVal
 * @param {*} leadsOrLogs
 */
export const getCursorFromDate = (dateVal) => {
  return btoa(
    "p=" +
      encodeURIComponent(
        moment(dateVal)
          .add(1, "minutes")
          .utc()
          .format(DATE_TIME_FORMAT_YYYY_MM_DD_T_HH_MM_SS + "+00:00")
      )
  );
};

/**
 * Get filter options for the vendor, buyer filters
 * @param {*} list
 * @param {*} idKey
 * @param {*} valueKey
 */
export const getFilterOptions = (list, idKey, valueKey) => {
  const tempList = [];
  Object.entries(list).forEach((item) => {
    tempList.push({
      id: item[1][idKey],
      value: item[1][valueKey]
    });
  });
  return tempList;
};

/**
 * Get tier filter's options
 * @param {*} list
 */
export const getTierOptions = (list) => {
  const tempList = [];
  Object.entries(list).forEach((item) => {
    item[1].tiers &&
      Object.entries(item[1].tiers).forEach((tier) => {
        tempList.push({
          id: tier[1].id,
          value: item[1].name + "-" + tier[1].name
        });
      });
  });
  return tempList;
};

const PING_POST_LOGS_URL_CALLS = "ping_post_logs/buyers/";
const PING_POST_LOGS_URL_WEBLEADS = "buyer_ping_post_logs/";

/**
 * Get API url on the basis of the tab
 * @param {*} tab
 */
export const getAPIURL = (setting) => {
  let apiURL = PING_POST_LOGS_URL_CALLS;
  if (setting === i18n.t("WEBLEADS_PARAM")) {
    apiURL = PING_POST_LOGS_URL_WEBLEADS;
  }
  return apiURL;
};

/**
 * Decided whether tier will be shown or not
 */
export const showTier = (setting, tab) => {
  return setting === i18n.t("WEBLEADS_PARAM") ||
    (setting === i18n.t("CALLS_PARAM") && tab === 3)
    ? true
    : false;
};

/**
 * Get query params
 * @param {*} setting
 * @param {*} date
 * @param {*} vendorTags
 * @param {*} verticalTags
 * @param {*} statusTags
 * @param {*} searchTags
 * @param {*} durationTags
 */
export const getQueryParams = (
  setting,
  cursor,
  vendorTags,
  buyerTags,
  verticalTags,
  statusTags,
  durationTags,
  searchTags,
  tierTags,
  buyerCampaignTags,
  vendorCampaignTags,
  eventTypeTags,
  janglIdTags
) => {
  return (
    "?cursor=" +
    cursor +
    (verticalTags ? "&vertical=" + getTagString(verticalTags) : "") +
    (vendorTags ? "&vendor_id=" + getTagString(vendorTags) : "") +
    (buyerTags ? "&buyer_id=" + getTagString(buyerTags) : "") +
    (statusTags ? "&status=" + getTagString(statusTags) : "") +
    (durationTags ? "&duration=" + getTagString(durationTags) : "") +
    (searchTags ? "&search=" + getTagString(searchTags) : "") +
    (tierTags ? "&vertical_tier_id=" + getTagString(tierTags) : "") +
    (vendorCampaignTags
      ? "&vendor_campaign_id=" + getTagString(vendorCampaignTags)
      : "") +
    (buyerCampaignTags
      ? "&buyer_campaign_id=" + getTagString(buyerCampaignTags)
      : "") +
    (eventTypeTags ? "&event=" + getTagString(eventTypeTags) : "") +
    (janglIdTags ? "&jangl_id=" + getTagString(janglIdTags) : "")
  );
};

/**
 * Formats current year and previous years date time
 * @param {*} dt
 * @returns
 */
export const formatDateTime = (dt) => {
  if (dt) {
    dt = dt.charAt(dt.length - 1) === "Z" ? dt : dt + "Z";
    dt = moment(dt);
    if (dt.year() === moment().year()) {
      return dt.format(DATE_TIME_FORMAT_MMM_D_H_MMA);
    } else {
      return dt.format(DATE_TIME_FORMAT_M_D_YY_H_MMA);
    }
  } else {
    return dt;
  }
};
