import React from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import { useTranslation } from "react-i18next";

export const CustomRoutes = () => {
  const { t } = useTranslation();
  return (
    <Switch>
      <Route
        exact
        path="/admin/calls/logs/"
        component={(props) => (
          <Dashboard {...props} setting={t("CALLS_PARAM")} />
        )}
      />
      <Route
        exact
        path="/admin/webleads/logs/"
        component={(props) => (
          <Dashboard {...props} setting={t("WEBLEADS_PARAM")} />
        )}
      />
    </Switch>
  );
};

export default CustomRoutes;
