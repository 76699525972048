import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  typography: {
    fontFamily: "Ubuntu",
    fontSize: "1.5rem"
  },
  palette: {
    primary: {
      main: localStorage.getItem("theme") && !["undefined", undefined].includes(localStorage.getItem("theme"))
        ? localStorage.getItem("theme")
        : "#999999",
      white: "#FFFFFF",
      darkTextcolor: "#707070",
      darkbgcolor: "#3E4147",
      mediumTextColor: "#4D4F5C",
      borderColor: "#D7DAE2",
      darkBorderColor: "#B2B2B2",
      disabledColor: "#DBDBDB",
      darkColor: "#E1E1E1",
      lightBlack: "#151618",
      lightGrey: "#959595",
      darkWhite: "#EFEFEF",
      darkGrey: "#333333",
      lightWhite: "#f5f5f5",
      grey: "#cccccc",
      lightBackground: "#eeeeee",
      blueColor: "#7a7f8b",
      green: "#60b258",
      red: "red",
      greyShade: "#DDDDDD",
      disabled: "#868686",
      headerText: "#484848",
      lightGreen: "#468847",
      lightRed: "#B94A48",
      yellow: "#FFFF00",
      shadowColor: "#00000021",
      button: "#7E7E7E",
      darkgreyShade: "#555555",
      errorColor: "#f44336",
      lightWhiteColor: "#F0F0F0",
      lightBlue: "#878C97",
      blockBorderColor: "#ff7108",
      liColor: "#4A4A4A",
      svgFillColor: "#9B9C9F",
      noFilterColor: "#c09853",
      ivrBackground: "#fcf8e3",
      ivrBorder: "#B9B9B9",
      dimGray: "#727272"
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  }
});
