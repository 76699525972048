import React, { useState, useEffect } from "react";

import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./LogsPopupModal.style";
import { GridTable, CustomModal } from "jangl-common-js";
import { getAPIURL } from "../Dashboard/LogsUtils";
import { useSelector, useDispatch } from "react-redux";
import { dataSelectors, dataOperations } from "../../store/logs";
import { LOGS_HEAD_CELLS } from "../../Constants";
import theme from "../../CustomTheme";

const LogsPopupModal = (props) => {
  const {
    tab,
    rowViewLogs,
    setRowViewLogs,
    allowCache,
    setting,
    onEyeClick
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openModalViewLogs, setOpenModalViewLogs] = useState(false);

  //TableData Selector
  const pingPostViewLogsAPIResult = useSelector(
    dataSelectors.getPingPostViewLogsData
  );
  const [pingPostViewLogsData, setPingPostViewLogsData] = useState([]);

  /**
   * On change ping post logs view data set value to pass in
   * children to show table data
   */
  useEffect(() => {
    if (pingPostViewLogsAPIResult) {
      setPingPostViewLogsData(pingPostViewLogsAPIResult);
      if (pingPostViewLogsAPIResult && pingPostViewLogsAPIResult.results) {
        setOpenModalViewLogs(true);
      }
    }
  }, [pingPostViewLogsAPIResult]);

  /**
   * Use effect to call the ping post logs view data API
   */
  useEffect(() => {
    if (rowViewLogs) {
      const queryParamLogs = "?jangl_id=" + rowViewLogs.jangl_id;
      dispatch(
        dataOperations.fetchPingPostViewLogs(
          false,
          setting,
          queryParamLogs,
          getAPIURL(tab === 0 ? 1 : 0, setting),
          allowCache
        )
      );
    }
  }, [dispatch, allowCache, setting, rowViewLogs, tab]);

  /**
   * Close the pop up and set rowViewLogs value to null
   */
  const closePopUpHandler = () => {
    setOpenModalViewLogs(false);
    setRowViewLogs(null);
    dispatch(dataOperations.fetchPingPostViewLogs(true));
  };

  /**
   * Eye icon click handler
   * @param {*} row
   */
  const onEyeClickHandler = (row) => {
    onEyeClick(row, tab === 0 ? 1 : 0);
  };

  return (
    <CustomModal
      open={openModalViewLogs}
      isTitle={true}
      closeHandler={closePopUpHandler}
      theme={theme}
      parentStyle={classes.popUpParent}
    >
      <Grid
        container
        alignItems="center"
        justify="flex-start"
        className={classes.popUpGrid}
      >
        <Grid item container xs={5} className={classes.logsGrid}>
          <Typography className={classes.logsHeading}>
            {t("JANGL_ID") + " : " + rowViewLogs.jangl_id}
          </Typography>
        </Grid>

        <Grid container alignItems="flex-start" className={classes.detailGrid}>
          <Grid
            container
            item
            justify="flex-end"
            xs={1}
            className={classes.detailLabel}
          >
            {t("TIMESTAMP_LABEL")}
          </Grid>
          <Grid item xs={2} className={classes.gridValue}>
            {rowViewLogs && rowViewLogs.timestamp}
          </Grid>

          <Grid
            container
            item
            justify="flex-end"
            xs={1}
            className={classes.detailLabel}
          >
            {t("VERTICAL_LABEL")}
          </Grid>

          <Grid item xs={2} className={classes.gridValue}>
            {rowViewLogs && rowViewLogs.vertical_name}
          </Grid>

          <Grid
            container
            item
            justify="flex-end"
            xs={1}
            className={classes.detailLabel}
          >
            {t("SOURCE_LABEL")}
          </Grid>

          <Grid item xs={tab === 0 ? 2 : 5} className={classes.gridValue}>
            {rowViewLogs && rowViewLogs.vendor_name}
          </Grid>
          {tab === 0 ? (
            <>
              <Grid
                container
                item
                justify="flex-end"
                xs={1}
                className={classes.detailLabel}
              >
                {t("ENDPOINT_LABEL")}
              </Grid>

              <Grid item xs={2} className={classes.gridValue}>
                {rowViewLogs && rowViewLogs.buyer_name}
              </Grid>
            </>
          ) : null}
        </Grid>
        <div className={classes.gridTableDiv}>
          <GridTable
            theme={theme}
            data={
              pingPostViewLogsData && pingPostViewLogsData.results
                ? pingPostViewLogsData.results
                : []
            }
            headCells={LOGS_HEAD_CELLS}
            showEyeIcon={true}
            onEyeClick={onEyeClickHandler}
            showEllipseIcon={false}
            textColor={theme.palette.primary.headerText}
            pagination={false}
            sortable={true}
          />
        </div>
      </Grid>
    </CustomModal>
  );
};

export default LogsPopupModal;
