import { createSelector } from "reselect";

const featureSelector = ({ auth }) => auth;

export const getAuth = createSelector(featureSelector, ({ auth }) => auth);

export const getUser = createSelector(featureSelector, ({ user }) => user);

export const getSiteInfo = createSelector(
  featureSelector,
  ({ siteInfo }) => siteInfo
);

export const getUserInfo = createSelector(
  featureSelector,
  ({ userInfo }) => userInfo
);
