import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  logsGrid: {
    marginTop: "6px"
  },
  logsHeading: {
    color: theme.palette.primary.darkbgcolor,
    fontFamily: "Ubuntu",
    fontSize: "25px",
    borderBottom: "4px solid " + theme.palette.primary.main
  },
  gridValue: {
    paddingLeft: "10px"
  },
  detailGrid: {
    marginTop: "35px",
    paddingRight: "40px"
  },
  detailLabel: {
    color: theme.palette.primary.darkbgcolor,
    fontFamily: "Ubuntu",
    fontSize: "16px",
    fontWeight: 500,
    wordBreak: "break-word"
  },
  gridTableDiv: {
    width: "98%",
    marginTop: "45px",
    overflowX: "hidden",
    overflowY: "auto",
    height: "60vh"
  },
  popUpParent: {
    width: "98%",
    maxWidth: "1100px",
    overflow: "hidden"
  },
  popUpGrid: {
    overflow: "auto",
    overflowX: "hidden",
    height: "80vh",
    width: "1100px"
  }
}));
